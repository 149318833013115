import {Pipe, PipeTransform} from '@angular/core'
import {TMenuQuality} from '@ellen/user-be'


const textMap: { [key in TMenuQuality]: string } = {
  cheap: 'Budgetvänlig',
  fancy: 'Guldkant',
  bbq: 'BBQ'
}

const colorMap: { [key in TMenuQuality]: string } = {
  cheap: '#0fbafa',
  fancy: '#fbbc04',
  bbq: '#ff5600'
}

const map = new Map([
  ['text', textMap],
  ['color', colorMap]
])

@Pipe({
  standalone: true,
  name: 'menuQuality'
})
/**
 * Pipe that translates values from {@link TMenuQuality} to text or color.
 * Text part should be removed once i18n pipe is in place.
 */
export class MenuQualityPipe implements PipeTransform {
  transform(value: TMenuQuality, type: 'text' | 'color' = 'text'): string {
    return map.get(type)![value]
  }
}

<div class="recipe-holder">
  @if (recipesService.isRecoveringRecipe$() || !recipe) {
    <mat-card class="recipe-loading-card">
      <mat-spinner class="eln-screen-loader"></mat-spinner>
    </mat-card>
  } @else {
    <eln-recipe-content
      [title]="recipe.title"
      [image]="recipe.imageUrl"
      [meal]="recipe.params.mealType"
      [sections]="recipe.sections"
      [familyMembers]="recipesService.familyRecipe$()!.familyMembers"
      [guests]="recipesService.familyRecipe$()!.guests"
      [quality]="recipesService.familyRecipe$()!.quality"
      [canDelete]="true"
      (delete)="onDiscardRecipeClick()"
      [canAddToCart]="true"
      [prompt]="recipe.prompt"
      [imagePrompt]="recipe.imagePrompt"
    ></eln-recipe-content>
  }
</div>

<eln-adds></eln-adds>
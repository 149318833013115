<div class="create-menu-warning-holder">
  <h3 class="create-menu-warning-title">
    Några familjemedlem profiler är inte kompletta
  </h3>

  <div class="create-menu-warning-actions-holder">
    <button class="eln-btn" mat-raised-button color="primary"
            [routerLink]="['/', INTERNAL_MODULE, MY_FAMILY_ROUTE]"
            mat-dialog-close>
      Gå till profil
    </button>

    <button class="eln-btn" mat-raised-button mat-dialog-close>
      Avbryt
    </button>
  </div>
</div>

import {inject, Injectable} from '@angular/core'
import {AwsService} from './aws.service'
import {UserService} from './user.service'
import {IUser} from '@ellen/user-be'

export interface IFileUpload {
  /**
   * File's content type to send to BE
   */
  contentType: string
  /**
   * File's data that will be uploaded to AWS S3
   */
  data: Blob
  /**
   * File's ID that is received from server once it is saved in AWS S3.
   */
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  public aws = inject(AwsService)

  private service = inject(UserService)
  /**
   * A list of all avatars as received from the "server"
   */
  public avatars = new Map<string, string | undefined>()

  private ownerId: string = ''

  public update(): void {
    this.service.family$.subscribe(
      {
        next: f => {
          this.ownerId = f.familyAdmins[0]

          f.familyMembers.forEach(u => {
            const existing = this.avatars.get(u.id)
            if (existing && existing !== u.avatar) {
              console.log(`Will remove ${existing}`)
            }
          })
          this.avatars.clear()
          f.familyMembers
            .filter(u => u.avatar)
            .forEach(u => {
              this.aws.getImageUrl(this.resolveParent(u), u.avatar!).subscribe({
                  next: url => {
                    u.avatarUrl = url
                  }
                })
                this.avatars.set(u.id, u.avatar)
              }
            )
          f.schedule?.forEach(s => {
            if (s.lunch) {
              this.aws.getImageUrl(this.ownerId, s.lunch.imageId!).subscribe({
                next: url => {
                  s.lunch!.imageUrl = url
                }
              })
            }
            if (s.dinner) {
              this.aws.getImageUrl(this.ownerId, s.dinner.imageId!).subscribe({
                next: url => {
                  s.dinner!.imageUrl = url
                }
              })
            }
          })
        }
      }
    )
  }

  private resolveParent(user: IUser): string {
    if (user.type === 'guest') {
      return user.id
    }
    return this.ownerId
  }
}

import {Component, input} from '@angular/core'

@Component({
  selector: 'eln-recipe-prompting',
  templateUrl: './recipe-prompting.component.html',
  styleUrls: ['./recipe-prompting.component.scss'],
  imports: [],
  standalone: true
})
export class RecipePromptingComponent {

  public prompt = input<string>()
  public imagePrompt = input<string>()
  public promptError = input<string>()
}

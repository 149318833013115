<eln-adds></eln-adds>

<div class="dream-machine-holder" #chatWindow>
  @if (!(dreamService.messages$().length > 0)) {
    <div class="dream-machine-loading-holder">
      <mat-spinner></mat-spinner>
    </div>
  }

  @for (message of dreamService.messages$(); track $index; let last = $last) {
    <div class="dream-machine-message-holder"
         [ngClass]="{'user-message': message.sender === 'Du', 'assistant-message': message.sender !== 'Du'}">
      <div class="message">
        <div [innerHTML]="message.html()">
        </div>
        @if (dreamService.processing$() && last) {
          <div class="wait-for-gpt">
            <div class="ball1"></div>
            <div class="ball2"></div>
            <div class="ball3"></div>
          </div>
        }
      </div>

      <div class="arrow"></div>
      <div class="outer-arrow"></div>
    </div>
  }
</div>

<div class="dream-machine-input-outer-holder">
  <div class="dream-machine-input-inner-holder">
    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="dream-machine-input">
        <textarea
          #chatArea
          class="dream-machine-input-textarea"
          [placeholder]="dreamService.processing$() ?'' : 'Skriv här...'"
          [formControl]="userInput"
          [maxlength]="maxTextLength"
          (keyup.enter)="sendMessage()"
          matInput
        ></textarea>

      <mat-icon matIconSuffix
                (click)="sendMessage()"
                (keydown.enter)="sendMessage()"
                class="dream-machine-input-send"
                [class.active]="userInput.value?.length"
      >send
      </mat-icon>
    </mat-form-field>

    <div class="dream-machine-input-count">
      {{ userInput.value?.length ?? '0' }}/{{ maxTextLength }}
    </div>
  </div>
</div>
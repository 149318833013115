import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'

@Component({
  selector: 'eln-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet],
  standalone: true
})
export class AppComponent {
}

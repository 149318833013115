<div class="cookbook-holder">
  @if ((userService.family$ | async)?.schedule?.length) {
    @for (menuDay of schedule; track $index) {
      <div class="cookbook-recipes-section-holder">
        @if (menuDay.day === 'quick') {
          <span class="cookbook-recipes-section-title">Snabb-meny</span>
        } @else {
          <span class="cookbook-recipes-section-title">
          {{ menuDay.day | date:'EEEE d/M':undefined:'sv' | titlecase }}
        </span>
        }

        @for (familyRecipe of menuDay.mealValues; track familyRecipe.meal) {
          <eln-recipe-content
            [title]="familyRecipe.title"
            [image]="familyRecipe.imageUrl"
            [meal]="familyRecipe.meal"
            [familyMembers]="familyRecipe.familyMembers"
            [guests]="familyRecipe.guests"
            [quality]="familyRecipe.quality"
            (select)="onTitleClick(menuDay, familyRecipe)"
            [canDelete]="true"
            (delete)="onDeleteClick(menuDay, familyRecipe)"
          ></eln-recipe-content>
        }
      </div>
    }
  } @else {
    <button class="eln-btn-big" color="primary" mat-raised-button
            [routerLink]="['/', INTERNAL_MODULE, CREATE_QUICK_MENU_ROUTE]">
      Skapa snabb-meny
    </button>
    <button class="eln-btn-big" color="primary" mat-raised-button
            [routerLink]="['/', INTERNAL_MODULE, CREATE_MENU_ROUTE, CREATE_MENU_PLAN_ROUTE]">
      Skapa meny
    </button>

    <button class="eln-btn-big" color="accent" mat-raised-button
            [routerLink]="['/', INTERNAL_MODULE, FAMILY_ROUTE]">
      Lägg till familjemedlem
    </button>
  }
</div>

<eln-adds></eln-adds>
import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, of, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {ICollectResult} from 'ellen-bankid-gw'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      if (!token) {
        return of(token)
      }
      if (token.expires < Date.now() && req.url.indexOf('/token') === -1) {
        return configService.token()
      } else {
        return of(token)
      }
    }),
    switchMap((token: ICollectResult | null) => {
      if (!token) {
        return next(req)
      }
      // Only send authorization bearer if endpoint is relevant
      if (req.url.includes('/api') || req.url.includes('/service/self') || req.url.includes('/service/logout')) {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token!.accessToken}`)
        })
        return next(authReq)
      }
      return next(req)
    })
  )
}

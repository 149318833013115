import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {SafeHtml} from '@angular/platform-browser'
import {ConfigService} from './config.service'
import {WEB_SOCKET} from '../application/window.provider'

export interface IDreamMessage {
  sender: string,
  text: string,
  html: WritableSignal<SafeHtml>
  complete: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DreamService {
  public processing$ = signal<boolean>(false)

  private configService = inject(ConfigService)
  private line: string = ''
  private startUl: boolean = false
  private startOl: boolean = false

  private lists: string[] = []

  private lastMessage: IDreamMessage = {
    text: 'Hej! Vad vill du laga idag?',
    html: signal<SafeHtml>(`<b>Hej! ${this.configService.loggedInUser$()?.name}</b> Vad vill du laga idag?`),
    sender: 'assistant',
    complete: true
  }
  public messages$ = signal<IDreamMessage[]>([this.lastMessage])

  private socket = inject(WEB_SOCKET)

  constructor() {
    this.socket.onmessage = (event: { data: string }) => {
      const data = JSON.parse(event.data)
      this.handleMessage(data.message, data.complete)
    }
  }

  /**
   * Send the prompt and then wait for new results. We expect
   * Chat GPT to start babbling
   * @param prompt
   */
  public sendMessage(prompt: string) {
    this.socket.send(JSON.stringify({prompt}))
    this.processing$.set(true)

    const message: IDreamMessage = {
      sender: 'Du',
      text: prompt,
      html: signal<string>(prompt),
      complete: true
    }

    this.lastMessage = {
      sender: 'Din assistent',
      text: '',
      html: signal<SafeHtml>(''),
      complete: false
    }
    this.messages$.set([...this.messages$(), message, this.lastMessage])
  }

  public handleMessage(message: string, complete: boolean) {
    // Letters or part of letters.
    this.line += message

    if (complete) {
      this.processing$.set(false)
      this.setLine(this.line)
      this.line = ''
      return
    }

    if (this.line.includes('\n')) {
      this.setLine(this.line)
      this.line = ''
    }
  }

  /**
   * Making this public, move to its own class later.
   * Consider a 3rd party processor.
   * @param line
   */
  public format(line: string): string {
    let item = line.trim()
    let prepend = ''

    // Bold
    let match = item.match(/(.*)\*{2}(.*)\*{2}(.*)/)
    if (match) {
      item = `${match[1]}<b>${match[2]}</b> ${match[3]}`
    }

    // List items.
    const isItemLine = item.charAt(0) === '-'
    if (!this.startUl && isItemLine) {
      this.startUl = true
      let prelist = ''
      if (this.lists.length > 0) {
        prelist = '<li>'
      }
      this.lists.push('ul')
      return `${prelist}<ul><li>${item.replace('-', '').trim()}</li>`
    }

    if (this.startUl && isItemLine) {
      return `<li>${item.replace('-', '').trim()}</li>`
    }

    if (this.startUl && !isItemLine) {
      this.startUl = false
      // It eiter an outer list or an inner list
      this.lists.pop()
      prepend = `</ul>`
    }

    // Numbered item.
    match = item.match(/^(\d{1,2})\. (.*)$/)
    if (match) {
      if (!this.startOl) {
        this.startOl = true
        this.lists.push('ol')
        return `${prepend}<ol><li>${match[2]}</li>`
      }
      return `<li>${match[2]}</li>`
      // If just an empty line we do not stop the list
    } else if (this.startOl && item) {
      this.startOl = false
      prepend += `</${this.lists.pop()}>`
    }
    match = item.match(/^(#{1,4}) (.*)$/)
    if (match) {
      return `${prepend}<h${match[1].length}>${match[2]}</h${match[1].length}>`
    }
    return `${prepend}<p>${item}</p>`
  }

  private setLine(text: string) {
    this.lastMessage.html.set(this.lastMessage.html() + this.format(text))
  }
}
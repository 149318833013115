<div class="shopping-list-screen-holder">
  <mat-card class="shopping-list-screen-card">
    <div class="shopping-list-logo">
      <img
        class="header-logo"
        alt="Joshu logo"
        src="assets/logo_joshu_black.png">
    </div>

    <div class="shopping-list-holder">
      @if (shoppingList.length) {
        <div class="shopping-list-top-actions">
          <button class="eln-btn-small" mat-raised-button color="warn"
                  (click)="clearAllItems()">
            Radera alla
          </button>
        </div>
      }

      @for (item of shoppingList; track $index) {
        <div class="shopping-list-item">
          <mat-checkbox
            (change)="checkItem(item, $event.checked)"
            [checked]="item.checked"
          ></mat-checkbox>

          <span class="shopping-list-item-name" [class.checked]="item.checked">
        {{ item.name }}
      </span>

          <mat-icon class="shopping-list-item-remove"
                    [tabindex]="$index"
                    (keydown.enter)="removeItem($index)"
                    (click)="removeItem($index)">do_not_disturb_on
          </mat-icon>
        </div>
      } @empty {
        <h4 class="shopping-list-empty">Inköpslistan är tom.<br>Lägg till
          ingredienser från ett av dina recept eller lägg till dina egna nedan.
        </h4>
      }
    </div>

    <div class="shopping-list-footer">
      <form [formGroup]="newItemForm">
        <mat-form-field subscriptSizing="dynamic" appearance="outline"
                        class="shopping-list-new-item-field">
          <input
            placeholder="Lägg till artiklar..."
            matInput
            [formControl]="newItemForm.controls.name"
            type="text"/>

          <mat-icon matIconSuffix
                    class="shopping-list-new-item-input-icon"
                    [class.disabled]="newItemForm.invalid"
                    tabindex="-1"
                    (keydown.enter)="newItemForm.valid && addItem()"
                    (click)="newItemForm.valid && addItem()">
            add
          </mat-icon>
        </mat-form-field>
      </form>

      <button class="eln-btn shopping-list-save-button" color="primary"
              mat-raised-button
              [disabled]="!isListModified"
              (click)="save()">
        @if (userService.isUpdatingFamily$()) {
          <mat-spinner></mat-spinner>
        } @else {
          Spara
        }
      </button>
    </div>
  </mat-card>
</div>

<eln-adds></eln-adds>
<div class="recipe-content-holder"
     [class.reversed]="isReversed"
     [class.flipping]="isFlipping"
     [class.no-sections]="!sections()">
  <div class="front-card-holder">
    <mat-card class="front-card">
      <span
        tabindex="-1"
        (keydown.enter)="select.emit()"
        (click)="select.emit()"
        class="recipe-content-title">{{ title() }}</span>

      <div class="recipe-content-image-holder"
           tabindex="-2"
           (keydown.enter)="onReverseClick()"
           (click)="onReverseClick()"
      >
        <mat-icon class="material-symbols-outlined info-button"
                  [ngClass]="meal()">
          info_circle
        </mat-icon>

        @if (image()) {
          <img [src]="image()" alt="recipe illustration">
        } @else {
          <img src="assets/image_demo_recipe.png" alt="recipe illustration">
        }
      </div>

      @if (sections()) {
        <div class="recipe-content-sections-holder">
          @for (section of sections()! | keyvalue:sorting; track section.key) {
            <div class="recipe-content-section-holder">
              <span
                class="recipe-content-section-title">{{ section.key | translate }}</span>

              <ul>
                @for (item of section.value; track $index) {
                  <li>{{ item }}</li>
                }
              </ul>
            </div>
          }
        </div>
      }
    </mat-card>
  </div>

  <div class="back-card-holder">
    <mat-card class="back-card">
      <div class="recipe-content-header-holder">
        <eln-meal-tag [meal]="meal()"></eln-meal-tag>

        <div class="recipe-content-header-actions-holder">
          @if (canDelete()) {
            <button class="remove-button" (click)="delete.emit()">
              <mat-icon class="material-symbols-outlined">delete</mat-icon>
            </button>
          }

          @if (canAddToCart() && !itemsAddedToCart && ingredientsToShop.length > 0) {
            <button class="remove-button" (click)="onAddToCartClick()">
              <mat-icon class="material-symbols-outlined">
                add_shopping_cart
              </mat-icon>
            </button>
          }

          <button class="info-button"
                  (click)="onReverseClick()">
            <mat-icon class="material-symbols-outlined">360</mat-icon>
          </button>
        </div>
      </div>

      <div class="recipe-content-config">
        <mat-divider></mat-divider>

        <!-- Family members participating in the recipe -->
        <div class="recipe-content-config-family-holder">
          @for (user of (userService.family$ | async)!.familyMembers; track $index) {
            <div class="recipe-content-config-family-member-holder">
              <eln-avatar
                [image]="user.avatarUrl"
                [size]="50"
                [selected]="familyMembers().includes(user.id)"
                [disabled]="!familyMembers().includes(user.id)"
              ></eln-avatar>
              <span>{{ (user.name | split)[0] }}</span>
            </div>
          }
        </div>

        <mat-divider></mat-divider>

        <!-- Special quality and guests -->
        <div class="recipe-content-config-extra-holder">
          <!-- Guests -->
          <div class="recipe-content-config-extra-guests">
            <span>Gäster</span>
            <span class="guests">{{ guests() }}</span>
          </div>

          <!-- Special quality -->
          @if (quality()) {
            <button mat-button
                    [disabled]="true"
                    [style.background-color]="quality()! | menuQuality:'color'"
            >{{ quality()! | menuQuality }}
            </button>
          }
        </div>

        @if (environment.showPrompting && prompt()) {
          <mat-divider></mat-divider>

          <eln-recipe-prompting
            [prompt]="prompt()"
            [imagePrompt]="imagePrompt()"
          ></eln-recipe-prompting>
        }
      </div>
    </mat-card>
  </div>
</div>
import {NgClass, NgForOf} from '@angular/common'
import {AfterViewChecked, Component, ElementRef, inject, viewChild} from '@angular/core'
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatError, MatFormField} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatProgressBar} from '@angular/material/progress-bar'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {DreamService} from '../../../services/dream.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'

@Component({
  selector: 'eln-dream',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    NgClass,
    MatButton,
    MatError,
    MatCard,
    MatProgressSpinner,
    MatProgressBar,
    MatFormField,
    MatInputModule,
    MatIcon,
    AppAddsComponent
  ],
  templateUrl: './dream.component.html',
  styleUrl: './dream.component.scss'
})
export class DreamComponent implements AfterViewChecked {
  private chatWindow = viewChild.required<ElementRef>('chatWindow')

  private chatArea = viewChild.required<ElementRef>('chatArea')

  public userInput: FormControl = new FormControl<string>('')
  public maxTextLength = 250

  protected readonly dreamService = inject(DreamService)


  public sendMessage(): void {
    if (this.userInput.value.trim()) {
      this.dreamService.sendMessage(this.userInput.value.trim())
      this.userInput.reset()
    }
  }

  public ngAfterViewChecked(): void {
    this.scrollToBottom()
    if (this.dreamService.messages$().length > 0 && !this.dreamService.processing$()) {
      this.userInput.enable()
      this.chatArea().nativeElement.focus()
    } else {
      this.userInput.disable()
    }
  }


  /**
   * Scroll to bottom when we get new messages (so that user don't need to)
   * @private
   */
  private scrollToBottom(): void {
    const chatWindowElement = this.chatWindow().nativeElement
    chatWindowElement.scrollTop = chatWindowElement.scrollHeight
  }
}

import {Component} from '@angular/core'

@Component({
  selector: 'eln-adds',
  standalone: true,
  imports: [],
  templateUrl: './app-adds.component.html',
  styleUrl: './app-adds.component.scss'
})
export class AppAddsComponent {

}

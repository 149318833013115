import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation, withInMemoryScrolling} from '@angular/router'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'
import {UserService} from './services/user.service'
import {LOCAL_STORAGE} from './application/localstorage.provider'
import {AwsService} from './services/aws.service'
import {ImagesService} from './services/images.service'
import {SIGNED_URL, WEB_SOCKET} from './application/window.provider'
import {getSignedUrl} from '@aws-sdk/s3-request-presigner'
import {environment} from '../environments/environment'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: LOCAL_STORAGE, useValue: localStorage},
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService, UserService],
      multi: true
    },
    // Temp sneaky way to start the service, will fix
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ImagesService) => () => s.update(),
      deps: [ImagesService, ConfigService, AwsService],
      multi: true
    },
    {
      provide: SIGNED_URL, useValue: getSignedUrl
    },
    {
      provide: WEB_SOCKET, useFactory: () => new WebSocket(environment.webSocketUrl)
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes,
      withHashLocation(),
      withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}

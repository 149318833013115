<eln-adds></eln-adds>

<div class="create-menu-summary-holder">
  <div #recipesHolder class="create-menu-summary-recipes-holder">
    @for (generatedRecipe of generatedRecipes; track $index) {
      <div class="create-menu-summary-recipe-holder"
           #recipeContent
           [id]="generatedRecipe.day + '-' + generatedRecipe.meal">
        @if (!generatedRecipe.error) {
          <eln-recipe-content
            [title]="generatedRecipe.recipe.title"
            [image]="generatedRecipe.recipe.imageUrl"
            [meal]="generatedRecipe.meal"
            [sections]="generatedRecipe.recipe.sections"
            [familyMembers]="generatedRecipe.config.familyMembers"
            [guests]="generatedRecipe.config.guests"
            [quality]="generatedRecipe.config.quality"
            [prompt]="generatedRecipe.recipe.prompt"
            [imagePrompt]="generatedRecipe.recipe.imagePrompt"
          ></eln-recipe-content>
        } @else {
          <mat-card class="create-menu-summary-error-recipe-card">
            <mat-icon>warning</mat-icon>
            <p>Oj... Ett fel har uppstått.</p>
            <button mat-raised-button class="eln-btn"
                    (click)="onRetryClick()">
              Försök igen
            </button>

            @if (environment.showPrompting) {
              <eln-recipe-prompting
                [prompt]="generatedRecipe.recipe.prompt"
                [imagePrompt]="generatedRecipe.recipe.imagePrompt"
                [promptError]="generatedRecipe.error"
              ></eln-recipe-prompting>
            }
          </mat-card>
        }
      </div>
    }
  </div>
</div>

@if (generatedRecipes.length && generatedRecipes[0].day !== 'quick') {
  <div class="create-menu-carousel-outer-holder">
    <div class="create-menu-carousel-inner-holder">
      <button class="create-menu-carousel-arrow"
              [class.hidden]="!carouselUtils.isLeftArrowActive()"
              (click)="carouselUtils.previousPage()">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <div class="create-menu-carousel-numbers-holder">
        @for (generatedRecipe of generatedRecipes; track $index) {
          <div #dayNumber class="create-menu-summary-day-number"
               [class.hidden]="carouselUtils.isNumberHidden($index)"
               [ngClass]="generatedRecipe.meal"
               [class.error]="generatedRecipe.error">
            @if (generatedRecipe.error) {
              <mat-icon class="error-version-icon">warning</mat-icon>
            } @else {
              <span>
                {{ generatedRecipe.day | date:'d/M':undefined:'sv' }}
              </span>
            }
          </div>
        }
      </div>

      <button class="create-menu-carousel-arrow"
              [class.hidden]="!carouselUtils.isRightArrowActive()"
              (click)="carouselUtils.nextPage()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
}

<div class="create-menu-footer-holder">
  <div class="create-menu-footer">
    <button class="eln-btn" color="accent"
            mat-raised-button
            [disabled]="recipesService.isGeneratingMenu$()"
            (click)="onRetryClick()">
      Ge mig ett nytt förslag
    </button>
    <button class="eln-btn" color="primary" mat-raised-button
            [disabled]="recipesService.isGeneratingMenu$() ||
              activeGeneratedRecipe()?.error"
            (click)="onSaveClick()">
      Lägg till i kokbok
    </button>
  </div>
</div>
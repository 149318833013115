<div class="create-quick-menu-holder">
  <button mat-raised-button color="primary" (click)="onMealClick('lunch')">
    Lunch
  </button>

  <button mat-raised-button color="accent" (click)="onMealClick('dinner')">
    Middag
  </button>
</div>

<eln-adds></eln-adds>
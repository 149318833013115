<eln-adds></eln-adds>

<div class="create-menu-plan-holder">
  <div #daysConfigHolder class="create-menu-plan-days-holder">
    @for (day of daysConfig; track $index) {
      @if (day.active) {
        <div #dayConfig>
          <eln-create-menu-plan-day-config
            [menuDayConfig]="day"
          ></eln-create-menu-plan-day-config>
        </div>
      }
    }
  </div>
</div>

<div class="create-menu-carousel-outer-holder">
  <div class="create-menu-carousel-inner-holder">
    @if (carouselUtils.isLeftArrowActive()) {
      <button class="create-menu-carousel-arrow"
              (click)="carouselUtils.previousPage()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    }

    <div class="create-menu-carousel-numbers-holder">
      @for (day of daysConfig; track $index) {
        @if (!day.active) {
          <div class="create-menu-plan-day-number disabled"
               [class.hidden]="carouselUtils.isNumberHidden($index)"
          >
            {{ (day.day | date:'EEEE':undefined:'sv')?.at(0) | uppercase }}
          </div>
        } @else {
          <div #dayNumber class="create-menu-plan-day-number"
               [class.disabled]="!day.active"
               [class.with-meals]="!day.allInactive"
               [class.hidden]="carouselUtils.isNumberHidden($index)"
          >
            {{ (day.day | date:'EEEE':undefined:'sv')?.at(0) | uppercase }}
          </div>
        }
      }
    </div>

    @if (carouselUtils.isRightArrowActive()) {
      <button class="create-menu-carousel-arrow"
              (click)="carouselUtils.nextPage()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    }
  </div>
</div>

<div class="create-menu-footer-holder">
  <div class="create-menu-footer">
    <button class="eln-btn" color="primary" mat-raised-button
            [disabled]="!isAnyMealActive() || recipesService.isGeneratingMenu$()"
            (click)="onGenerateMenuClick()">
      Skapa min meny
    </button>
  </div>
</div>
import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {
  CREATE_MENU_PLAN_ROUTE,
  CREATE_MENU_ROUTE,
  CREATE_MENU_SUMMARY_ROUTE,
  CREATE_QUICK_MENU_ROUTE,
  DREAM_MACHINE_ROUTE,
  EXTERNAL_MODULE,
  INTERNAL_MODULE,
  MY_COOKBOOK_ROUTE,
  MY_FAMILY_ROUTE,
  RECIPE_ROUTE,
  ROUTE_PARAM_DAY,
  ROUTE_PARAM_MEAL,
  SHOPPING_LIST_ROUTE
} from './application/constants'
import {CreateMenuPlanComponent} from './ui/internal/create-menu/create-menu-plan/create-menu-plan.component'
import {CreateMenuSummaryComponent} from './ui/internal/create-menu/create-menu-summary/create-menu-summary.component'
import {CreateQuickMenuComponent} from './ui/internal/create-quick-menu/create-quick-menu.component'
import {DreamComponent} from './ui/internal/dream/dream.component'
import {FamilyComponent} from './ui/internal/family/family.component'
import {MyCookbookComponent} from './ui/internal/my-cookbook/my-cookbook.component'
import {RecipeComponent} from './ui/internal/recipe/recipe.component'
import {ShoppingListComponent} from './ui/internal/shopping-list/shopping-list.component'

export const routes: Routes = [
  {
    path: EXTERNAL_MODULE,
    loadComponent: () => import('./ui/external/external-home/external-home.component').then(m => m.ExternalHomeComponent)
  },
  {
    path: 'external/login',
    loadComponent: () => import('./ui/external/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: INTERNAL_MODULE,
    loadComponent: () => import('./ui/internal/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard],
    children: [
      {
        // Used after selecting a recipe in MyCookbook
        path: `${MY_COOKBOOK_ROUTE}/${RECIPE_ROUTE}/:${ROUTE_PARAM_DAY}/:${ROUTE_PARAM_MEAL}`,
        pathMatch: 'full',
        component: RecipeComponent
      },
      {
        path: MY_COOKBOOK_ROUTE,
        pathMatch: 'full',
        component: MyCookbookComponent
      },
      {
        path: DREAM_MACHINE_ROUTE,
        pathMatch: 'full',
        component: DreamComponent
      },
      {
        path: SHOPPING_LIST_ROUTE,
        pathMatch: 'full',
        component: ShoppingListComponent
      },
      {
        path: MY_FAMILY_ROUTE,
        pathMatch: 'full',
        component: FamilyComponent
      },
      {
        path: CREATE_MENU_ROUTE,
        children: [
          {
            path: CREATE_MENU_PLAN_ROUTE,
            component: CreateMenuPlanComponent
          },
          {
            path: CREATE_MENU_SUMMARY_ROUTE,
            component: CreateMenuSummaryComponent
          },
          {
            path: '**',
            redirectTo: CREATE_MENU_PLAN_ROUTE
          }
        ]
      },
      {
        path: CREATE_QUICK_MENU_ROUTE,
        pathMatch: 'full',
        component: CreateQuickMenuComponent
      },
      {
        path: '**',
        redirectTo: MY_COOKBOOK_ROUTE
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: EXTERNAL_MODULE
  },
  {
    path: '**',
    redirectTo: EXTERNAL_MODULE
  }
]

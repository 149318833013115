import {AsyncPipe, DatePipe, KeyValuePipe, NgClass, TitleCasePipe} from '@angular/common'
import {Component, inject, input, output} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatDivider} from '@angular/material/divider'
import {MatIcon} from '@angular/material/icon'
import {DailyMeals, IMenuMealConfig, MenuDayConfig, MenuQualities, TDailyMeal, TMenuQuality} from '@ellen/user-be'
import {DailyMealPipe} from '../../../../../pipes/menu-meal.pipe'
import {MenuQualityPipe} from '../../../../../pipes/menu-quality.pipe'
import {SplitPipe} from '../../../../../pipes/split.pipe'
import {UserService} from '../../../../../services/user.service'
import {AvatarComponent} from '../../../../common/avatar/avatar.component'

export type TMenuMealConfigFE = IMenuMealConfig & { active: boolean }

export class MenuDayConfigFE extends MenuDayConfig {
  active: boolean = true
  override lunch: TMenuMealConfigFE
  override dinner: TMenuMealConfigFE

  constructor(date: number, lunch: IMenuMealConfig, dinner: IMenuMealConfig) {
    super({day: date, lunch, dinner})
    this.lunch = {...lunch, active: false}
    this.dinner = {...dinner, active: false}
  }

  get allInactive(): boolean {
    return !this.lunch.active && !this.dinner.active
  }

  public getModelBE(): MenuDayConfig {
    const configDay = new MenuDayConfig(this)
    DailyMeals.forEach(mealKey => {
      if (!this[mealKey].active) {
        delete configDay[mealKey]
      }
    })
    return configDay
  }
}

@Component({
  selector: 'eln-create-menu-plan-day-config',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatDivider,
    AvatarComponent,
    MenuQualityPipe,
    KeyValuePipe,
    DailyMealPipe,
    MatIcon,
    SplitPipe,
    NgClass,
    DailyMealPipe,
    DatePipe,
    TitleCasePipe,
    AsyncPipe
  ],
  templateUrl: './create-menu-plan-day-config.component.html',
  styleUrl: './create-menu-plan-day-config.component.scss'
})
export class CreateMenuPlanDayConfigComponent {

  public menuDayConfig = input.required<MenuDayConfigFE>()

  public removeDay = output()

  public userService: UserService = inject(UserService)

  protected readonly DailyMeals = DailyMeals
  protected readonly MenuQualities = MenuQualities

  public toggleMealActive(dailyMeal: TDailyMeal) {
    this.menuDayConfig()[dailyMeal].active =
      !this.menuDayConfig()[dailyMeal].active
  }

  public toggleQuality(mealConfig: IMenuMealConfig, quality: TMenuQuality) {
    // Remove if already present, if not, add it
    if (mealConfig.quality === quality) {
      delete mealConfig.quality
    } else {
      mealConfig.quality = quality
    }
  }

  public toggleFamilyMember(mealConfig: IMenuMealConfig, memberId: string) {
    // Remove if already present, if not, add it
    if (mealConfig.familyMembers.includes(memberId)) {
      mealConfig.familyMembers = mealConfig.familyMembers
        .filter(fm => fm !== memberId)
    } else {
      mealConfig.familyMembers.push(memberId)
    }
  }

  public addGuest(mealConfig: IMenuMealConfig) {
    mealConfig.guests += 1
  }

  public removeGuest(mealConfig: IMenuMealConfig) {
    mealConfig.guests = Math.max(mealConfig.guests - 1, 0)
  }
}

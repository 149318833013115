import {Pipe, PipeTransform} from '@angular/core'
import {TDailyMeal} from '@ellen/user-be'


const map: { [key in TDailyMeal]: string } = {
  lunch: 'Lunch',
  dinner: 'Middag'
}

@Pipe({
  standalone: true,
  name: 'dailyMeal'
})
/**
 * Pipe that translates values from {@link TDailyMeal} to text.
 * It should be removed once i18n pipe is in place.
 */
export class DailyMealPipe implements PipeTransform {
  transform(value: TDailyMeal): string {
    return map[value]
  }
}

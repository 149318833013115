@if (prompt()) {
  <strong>Prompt</strong>
  {{ prompt() }}
}
@if (imagePrompt()) {
  <strong>Image prompt</strong>
  {{ imagePrompt() }}
}
@if (promptError()) {
  <strong>GPT Error</strong>
  {{ promptError() }}
}
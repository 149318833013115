import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog'
import {RouterLink} from '@angular/router'
import {
  DEFAULT_DIALOG_STYLE,
  INTERNAL_MODULE,
  MY_FAMILY_ROUTE
} from '../../../../application/constants'

@Component({
  selector: 'eln-create-menu-warning-dialog',
  standalone: true,
  imports: [MatButton, MatDialogClose, RouterLink],
  templateUrl: './create-menu-warning-dialog.component.html',
  styleUrl: './create-menu-warning-dialog.component.scss'
})
export class CreateMenuWarningDialogComponent {

  protected readonly MY_FAMILY_ROUTE = MY_FAMILY_ROUTE
  protected readonly INTERNAL_MODULE = INTERNAL_MODULE

  public static open(dialog: MatDialog): MatDialogRef<CreateMenuWarningDialogComponent> {
    return dialog.open(CreateMenuWarningDialogComponent, DEFAULT_DIALOG_STYLE)
  }
}

import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {Router} from '@angular/router'
import {IMenuDayConfig, TDailyMeal} from '@ellen/user-be'
import {CREATE_MENU_ROUTE, CREATE_MENU_SUMMARY_ROUTE, INTERNAL_MODULE} from '../../../application/constants'
import {RecipesService} from '../../../services/recipes.service'
import {UserService} from '../../../services/user.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {GptLoaderComponent} from '../../common/gpt-loader/gpt-loader.component'
import {first} from 'rxjs'
import {switchMap} from 'rxjs/operators'
import {User} from '../../../utils/user.class'

@Component({
  selector: 'eln-create-quick-menu',
  standalone: true,
  imports: [
    MatButton,
    GptLoaderComponent,
    AppAddsComponent
  ],
  templateUrl: './create-quick-menu.component.html',
  styleUrl: './create-quick-menu.component.scss'
})
export class CreateQuickMenuComponent {

  public userService: UserService = inject(UserService)
  public recipesService: RecipesService = inject(RecipesService)

  private router: Router = inject(Router)

  public onMealClick(meal: TDailyMeal) {
    this.userService.family$.pipe(
      first(),
      switchMap(f => this.recipesService.getMenu(f.familyMembers,
        [this.generateAutomaticMeal(meal, f.familyMembers)]))
    ).subscribe(() =>
      // Navigate to create menu summary screen after receiving menus.
      this.router
        .navigate(['/', INTERNAL_MODULE, CREATE_MENU_ROUTE, CREATE_MENU_SUMMARY_ROUTE])
        .then()
    )
  }

  private generateAutomaticMeal(activeMeal: TDailyMeal, familyMembers: User[]): IMenuDayConfig {
    return {
      day: 'quick',
      [activeMeal]: {
        familyMembers: familyMembers.map(u => u.id),
        guests: 0
      }
    }
  }
}
